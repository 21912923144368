import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import AppConfig from "../../Config";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../Widget";

const Wrapper = styled.div`
  margin-bottom: 30px;
  position: relative;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &.loading-mask {
      background-color: rgba(255, 255, 255, 0.6);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
    }

    &.title-wrapper {
      margin-bottom: 12px;
    }

    & > .title {
      font-size: 16px;
      color: ${AppConfig["global"].themeColor};
    }

    & > .price {
      font-size: 16px;
      color: #575757;
    }

    & > .normal {
      font-size: 14px;
      color: #575757;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > p {
        font-size: 14px;
        color: #575757;
      }
    }

    & button {
      width: 48px;
      height: 30px;
      background-color: ${AppConfig["global"].themeColor};
      color: #fff;
      font-size: 14px;
      border: 0;
      cursor: pointer;
      outline: none;
    }
  }
`;

class CartItem extends Component {
  constructor(props) {
    super(props);
    let { config } = this.props.data;
    this.state = {
      loading: false,
      quantity: config ? config.quantity : 0
    };
  }

  render() {
    let { loading, quantity } = this.state;
    let { data, editMode, appActions, itemIndex } = this.props;
    let { config, product, promotions, calculation } = data;

    return (
      <Wrapper editMode={editMode}>
        {loading && (
          <div className="loading-mask">
            <Widget.Spinner />
          </div>
        )}

        <div className="title-wrapper">
          <p className="title">{product.name}</p>
          <p className="price">{product.price}元</p>
        </div>
        {/* <div>
          {promotions.map((p, idx) => (
            <p key={idx} className="normal">
              {p.name} {this._getPromotionsDisplay(p)}
            </p>
          ))}
        </div> */}
        <div>
          <div>
            <p>數量</p>
            {editMode ? (
              <>
                <Widget.CustomQuantityInput
                  value={quantity}
                  disabled={loading}
                  onChange={e => {
                    if (parseInt(e.target.value) < 1) {
                      return;
                    } else {
                      this.setState({ quantity: e.target.value });
                    }
                  }}
                  extraCss="margin-left: 13px; margin-right: 10px;"
                />
                {config.quantity !== quantity && (
                  <button
                    type="button"
                    onClick={() => this._editItemQuantity(quantity)}
                  >
                    確認
                  </button>
                )}
              </>
            ) : (
              <p>-{config.quantity}個</p>
            )}
          </div>
          {editMode && (
            <button type="button" onClick={() => this._removeItemFromCart()}>
              刪除
            </button>
          )}
        </div>
      </Wrapper>
    );
  }

  _removeItemFromCart = () => {
    let { appActions, itemIndex } = this.props;
    this.setState({ loading: true });
    appActions
      .removeItemFromCart(itemIndex)
      .then(result => {
        this.setState({ loading: false });
      })
      .catch(err => 0);
  };

  _editItemQuantity = quantity => {
    let { appActions, itemIndex, data } = this.props;
    let { config, product, promotions, calculation } = data;
    this.setState({ loading: true });

    if (!quantity || quantity === "0") {
      this.setState({ quantity: config.quantity, loading: false });
      return;
    } else {
      quantity = parseInt(quantity);
    }

    appActions
      .editItemFromCart(itemIndex, JSON.stringify({ ...config, quantity }))
      .then(result => {
        console.log("result after edit>>>", result);
        this.setState({ loading: false });
      })
      .catch(err => 0);
  };

  _getPromotionsDisplay = promotion => {
    switch (promotion.method) {
      case "DISCOUNT_BY_PRICE_X":
        return `折扣${promotion.x}元`;
      case "DISCOUNT_BY_PERCENT_X":
        return `${100 - promotion.x}折優惠`;
      case "BUY_X_GET_Y_FREE":
        return `買${promotion.x} 送${promotion.y}`;
      case "BUY_X_Y_TOGETHER_GET_DISCOUNT_Z":
        return `組合優惠`;
      default:
        return `符合折扣條件`;
    }
  };
}

export default connect(
  (state, ownProps) => ({}),
  ActionCreator
)(CartItem);
