import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import TextInputField from "../../Components/TextInputField";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import ParseQuery from "../../Utils/ParseQuery";
import * as Widget from "../../Components/Widget";
import * as Widget2 from "../../Components/Widget2";
import Login from "../../Components/Login";
import OrderStatusBar from "../../Components/OrderStatusBar";
import OrderBasket from "../../Components/OrderBasket";
import CartConfig from "../../Components/CartConfig";
import OrderPaymentInfo from "../../Components/OrderPaymentInfo";
import OrderBuyerInfo from "../../Components/OrderBuyerInfo";
import PaymentButton from "../../Components/PaymentButton";

const UI_STATE = {
  CART: "CART",
  PAYMENT: "PAYMENT"
};

const UI_STATE_TO_STEP_MAP = {
  CART: 1,
  PAYMENT: 2
};

class CartPage extends React.Component {
  constructor(props) {
    super(props);

    let { location } = this.props;
    let fromCvs = location.search.indexOf("cvs=true") > -1;

    this.state = {
      loading: false,
      uiState: UI_STATE.CART,
      paymentConfig: {
        paymentType: fromCvs ? "ecpay_cvs" : "ecpay"
      },
      logisticsConfig: {
        LogisticsType: fromCvs ? "CVS" : "post",
        LogisticsSubType: "",
        IsCollection: ""
      },
      order: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let { location, appActions, navActions } = this.props;
    if (location.search.indexOf("data") > -1) {
      let dataEncode = location.search.slice(location.search.indexOf("data"));
      let queryParams = ParseQuery(dataEncode);
      let data = JSON.parse(decodeURIComponent(queryParams.data));

      if (data.token) {
        window.localStorage.setItem("token", data.token);
        appActions.autoLogin().then(() => console.log("social login success"));
        setTimeout(() => navActions.push(location.pathname), 1500);
      }
    }

    this._setDefaultLogisticsConfig();
  }

  componentDidUpdate() {
    this._setDefaultLogisticsConfig();
  }

  render() {
    let {
      cartData,
      localCartDataConfig,
      appActions,
      navActions,
      profile
    } = this.props;
    let {
      paymentConfig,
      logisticsConfig,
      uiState,
      loading,
      order
    } = this.state;

    console.log("----DBG logisticsConfig", logisticsConfig);

    if (!profile) {
      return (
        <Widget.MaxWidth width={800} extraCss="padding: 20px;">
          <Widget.Center>
            <Login.Button />
          </Widget.Center>
        </Widget.MaxWidth>
      );
    }

    if (!cartData) {
      return (
        <Wrapper>
          <Widget.LoadingWrapper
            loading={true}
            spinnerSize={50}
            spinnerColor={"lightgray"}
            css={`
              width: 100%;
              height: calc(100vh - 60px);
              & > .loading-container {
                background-color: white;
              }
            `}
          />
        </Wrapper>
      );
    }

    if (cartData && cartData.items.length === 0) {
      return (
        <Wrapper>
          <div
            className="container"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <img
              src="../../images/empty-cart.png"
              style={{ width: 150, height: 150, margin: 5 }}
            />
            <div className="empty-cart-text">目前購物車</div>
            <div className="empty-cart-text">沒有任何商品</div>
            <Widget.Col css="margin-left: 25px; margin-top: 20px;">
              <Widget2.BasicButton
                onClick={() => navActions.push("/")}
                css="margin-bottom: 20px; background: white; color: #0075c1;"
              >
                回首頁
              </Widget2.BasicButton>
              <Widget2.BasicButton
                onClick={() => navActions.push("/products/simCard")}
                css="margin-bottom: 20px;"
              >
                來看全球上網
              </Widget2.BasicButton>
              <Widget2.BasicButton
                onClick={() => navActions.push("/products/travelAttachment")}
              >
                來看旅行小物
              </Widget2.BasicButton>
            </Widget.Col>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="container">
          <div className="left-section">
            <OrderStatusBar step={UI_STATE_TO_STEP_MAP[uiState]} />
            <div className="info">
              {(() => {
                switch (uiState) {
                  case UI_STATE.CART:
                    return (
                      <>
                        <CartConfig
                          isEditable
                          config={{
                            name: profile.name,
                            phone: profile.phone,
                            ...localCartDataConfig
                          }}
                          updateConfig={data =>
                            appActions.editLocalCartConfig(data)
                          }
                        />
                        <OrderPaymentInfo
                          isEditable
                          config={{
                            paymentConfig,
                            logisticsConfig,
                            CVSStoreID: localCartDataConfig.CVSStoreID,
                            CVSStoreName: localCartDataConfig.CVSStoreName
                          }}
                          updateConfig={(paymentConfig, logisticsConfig) => {
                            this.setState({ paymentConfig, logisticsConfig });
                            appActions
                              .editLocalCartConfig({
                                ...localCartDataConfig,
                                ...paymentConfig,
                                ...logisticsConfig
                              })
                              .then(() =>
                                appActions.editCartConfig(
                                  JSON.stringify(this.props.localCartDataConfig)
                                )
                              );
                          }}
                        />
                      </>
                    );
                  case UI_STATE.PAYMENT:
                    return (
                      <OrderBuyerInfo order={order} showPaymentBtn={false} />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
          <div className="right-section">
            <OrderBasket
              cartData={cartData}
              editMode={uiState === UI_STATE.CART}
              openAsDefault={true}
            />
          </div>
        </div>

        <div className="footer">
          {uiState !== UI_STATE.PAYMENT ? (
            <Widget.SubmitButton
              text={"下一步"}
              loading={loading}
              type="button"
              onClick={this._submit}
              disabled={!this._isAllowNextStep()}
            />
          ) : (
            <PaymentButton
              order={order}
              css={`
                color: ${AppConfig.global.themeColor};
                background-color: white;
              `}
            />
          )}
        </div>
      </Wrapper>
    );
  }

  _setDefaultLogisticsConfig = () => {
    let { appActions, profile, localCartDataConfig } = this.props;

    if (
      profile &&
      this.state.logisticsConfig.LogisticsType === "post" &&
      localCartDataConfig.LogisticsType !== "post"
    ) {
      appActions
        .editLocalCartConfig({
          ...localCartDataConfig,
          LogisticsType: "post",
          LogisticsSubType: "",
          IsCollection: ""
        })
        .then(() =>
          appActions.editCartConfig(
            JSON.stringify(this.props.localCartDataConfig)
          )
        )
        .catch(err => console.warn(err));
    }

    if (
      profile &&
      localCartDataConfig.length > 0 &&
      this.state.logisticsConfig.LogisticsSubType !==
        localCartDataConfig.LogisticsSubType
    ) {
      this.setState({
        logisticsConfig: {
          LogisticsSubType: localCartDataConfig.LogisticsSubType,
          IsCollection: localCartDataConfig.IsCollection
        }
      });
    }
  };

  _submit = () => {
    let { appActions, navActions, profile, localCartDataConfig } = this.props;
    let { paymentConfig, logisticsConfig, uiState } = this.state;

    if (uiState === UI_STATE.CART) {
      this.setState({ loading: true });
      appActions
        .editCartConfig(JSON.stringify(localCartDataConfig))
        .then(() => {
          return appActions.createOrder({
            currency: "TWD",
            paymentType: paymentConfig.paymentType,
            logisticsType: logisticsConfig.LogisticsType,
            title: `user-${profile.id}`
          });
        })
        .then(order => this.setState({ uiState: UI_STATE.PAYMENT, order }))
        .catch(err => 0)
        .then(() => this.setState({ loading: false }));
    }
  };

  _isAllowNextStep = () => {
    let {
      paymentConfig: { paymentType }
    } = this.state;
    let {
      localCartDataConfig: { name, phone, address, email }
    } = this.props;
    return name && phone && address && email && paymentType;
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;

  & > .container {
    flex: 1;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: flex-start;
    }

    & > .empty-cart-text {
      margin-left: 25px;
      margin-bottom: 5px;
      max-width: 150;
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #0a6fb7;
    }

    & > .left-section {
      width: 621px;
      margin-right: 100px;
      @media screen and (max-width: 1200px) {
        flex: 1;
        margin-right: 0;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }

      & > .info {
        width: 100%;
        height: 100%;

        & > .title {
          margin-top: 52px;
          font-size: 16px;
          color: #575757;

          & > span {
            font-size: 14px;
            color: #f92b2b;
            margin-left: 10px;
          }
        }

        & > .hint {
          font-size: 14px;
          color: #a4a4a4;
        }

        & > .hint-wrapper {
          margin-top: 30px;
          margin-bottom: 46px;
          padding: 30px;
          border: 1px solid #dadada;
          @media screen and (max-width: 767px) {
            padding: 20px;
          }

          & > p {
            font-size: 14px;
            color: ${AppConfig["global"].themeColor};
          }
        }
      }
    }

    & > .right-section {
      width: 400px;

      @media screen and (max-width: 1200px) {
        width: 620px;
        margin-bottom: 60px;
      }
      @media screen and (max-width: 767px) {
        width: 335px;
      }
      @media screen and (max-width: 374px) {
        width: 300px;
      }
    }
  }

  & > .footer {
    height: 120px;
    display: flex;
    background-color: ${AppConfig["global"].themeColor};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 160px;
    @media screen and (max-width: 1200px) {
      padding: 0 65px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }

    & > button {
      width: 160px;
      height: 40px;
      border: 0;
      font-size: 14px;
      color: ${AppConfig["global"].themeColor};
      background-color: #fff;
      cursor: pointer;
      outline: 0;

      &:disabled {
        background-color: #a9a9a9;
        color: #fff;
        opacity: 0.9;
        cursor: not-allowed;
      }
    }
  }

  ${AppConfig["Templates/CartPage"].css || ""}
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      cartData: Selectors.getCartData(state),
      localCartDataConfig: Selectors.getLocalCartDataConfig(state)
    }),
    ActionCreator
  )(CartPage)
);
