import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import Constants from "../../Domain/constants";

const Wrapper = styled.a`
  background-color: ${AppConfig["global"].themeColor};
  width: 160px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  ${props => props.css}
`;

class PaymentButton extends React.Component {
  render() {
    let { order, css = "" } = this.props;
    let stgApi = `${Constants.apiUrl}/checkout/request/stage/?order=${
      order.id
    }&token=${localStorage.getItem("token")}`;
    let prodApi = `${Constants.apiUrl}/checkout/request/?order=${
      order.id
    }&token=${localStorage.getItem("token")}`;

    return (
      <Wrapper css={css} href={Constants.env === "prod" ? prodApi : stgApi}>
        前往結帳
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    //
  }),
  ActionCreator
)(PaymentButton);
