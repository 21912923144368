import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import AppConfig from "../../Config";
import ActionCreator from "../../ActionCreator";
import { dateParser } from "../../Utils/DateUtil";
import jsonParser from "../../Utils/JsonParser";
import Constants from "../../Domain/constants";
import PaymentButton from "../PaymentButton";

const Wrapper = styled.div`
  width: 100%;

  & .color-grey {
    background-color: #a9a9a9;
  }
  & .color-red {
    background-color: tomato;
  }
  & .color-green {
    background-color: #0cc10c;
  }

  & .pay-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  & > .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    & > p {
      font-size: 14px;
      color: #a4a4a4;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  & > .info-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .payment-wrapper {
      & > p {
        font-size: 16px;
        color: #575757;
        margin-top: 10px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    & > .status-wrapper {
      width: 114px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      & > p {
        font-size: 14px;
        color: #fff;
      }
    }
  }
`;

const PAYMENT_TYPE_DISPLAY_MAP = {
  ecpay: "線上刷卡",
  offline: "超商付款"
};

const PAYMENT_STATUS_DISPLAY_MAP = {
  waiting: "尚未付款",
  success: "已付款",
  failure: "付款失敗"
};

class OrderBuyerInfo extends Component {
  render() {
    let { order, appActions, showPaymentBtn = true } = this.props;
    let cart = jsonParser(order.cart, { config: {} });

    return (
      <Wrapper>
        <div className="title-wrapper">
          <p />
          <p>{dateParser(order.created)}</p>
        </div>
        <div className="info-wrapper">
          <div className="payment-wrapper">
            <p>付款方式：{PAYMENT_TYPE_DISPLAY_MAP[order.payment_type]}</p>
            <p>取貨人：{cart.config.name || "---"}</p>
            <p>取貨地點：{cart.config.address || "---"}</p>
          </div>
          {(() => {
            let colorClass = "color-grey";
            switch (order.payment_status) {
              case "waiting":
                break;
              case "success":
                colorClass = "color-green";
                break;
              case "failure":
                colorClass = "color-red";
                break;
              default:
                colorClass = "color-grey";
                break;
            }
            return (
              <div className={`status-wrapper ${colorClass}`}>
                <p>
                  {PAYMENT_STATUS_DISPLAY_MAP[order.payment_status] ||
                    order.payment_status}
                </p>
              </div>
            );
          })()}

          {showPaymentBtn &&
            order.payment_type === "ecpay" &&
            order.payment_status === "waiting" && (
              <div className="pay-button-wrapper">
                <PaymentButton order={order} />
              </div>
            )}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  null,
  ActionCreator
)(OrderBuyerInfo);
