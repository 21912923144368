import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../../Domain/constants";
import RadioSelectorGroupField from "../RadioSelectorGroupField";
import AppConfig from "../../Config";
import * as Widget from "../../Components/Widget2";

const ALL_SUPPORTED_PAY_TYPE = {
  ecpay: {
    label: "線上刷卡",
    value: "ecpay"
  },
  ecpay_cvs: {
    label: "超商貨到付款 $60",
    value: "ecpay_cvs"
  }
};

const ALL_SUPPORTED_DELIVERY_METHOD = {
  post: {
    label: "郵寄 $40",
    value: "post"
  },
  Home: {
    label: "宅配 $100 (宅配通)",
    value: "Home"
  }
};

const ALL_SUPPORTED_CVS_TYPE = {
  seven_eleven: {
    label: "7-11",
    value: {
      LogisticsType: "CVS",
      LogisticsSubType: "UNIMARTC2C",
      IsCollection: "N"
    }
  }
};

class OrderPaymentInfo extends Component {
  render() {
    let { config, updateConfig, isEditable = false } = this.props;
    let options = AppConfig.global.supportedPayType.map(
      t => ALL_SUPPORTED_PAY_TYPE[t]
    );
    let deliveryOptions = AppConfig.global.supportedDeliveyMethod.map(
      m => ALL_SUPPORTED_DELIVERY_METHOD[m]
    );
    let cvsOptions = AppConfig.global.supportedCVSType.map(
      t => ALL_SUPPORTED_CVS_TYPE[t]
    );

    return (
      <Wrapper>
        <p className="title">選擇付款方式</p>
        {isEditable ? (
          <RadioSelectorGroupField
            value={config.paymentConfig.paymentType}
            onChange={paymentType => {
              if (paymentType === "ecpay") {
                let paymentConfig = { paymentType };
                let logisticsConfig = {
                  LogisticsType: "",
                  LogisticsSubType: "",
                  IsCollection: ""
                };
                updateConfig(paymentConfig, logisticsConfig);
              } else {
                //ecpay_cvs
                let paymentConfig = { paymentType };
                let logisticsConfig = {
                  LogisticsType: "CVS",
                  LogisticsSubType: "",
                  IsCollection: ""
                };
                updateConfig(paymentConfig, logisticsConfig);
              }
            }}
            options={options}
          />
        ) : (
          <span className="text margin-top-10">
            {options.find(o => o.value === config.paymentType).label}
          </span>
        )}

        {(() => {
          switch (config.paymentConfig.paymentType) {
            case "ecpay":
              return (
                <>
                  <p className="title">選擇取貨方式</p>
                  {isEditable ? (
                    <RadioSelectorGroupField
                      value={config.logisticsConfig.LogisticsType}
                      onChange={LogisticsType => {
                        let paymentConfig = { ...config.paymentConfig };
                        let logisticsConfig = {
                          ...config.logisticsConfig,
                          LogisticsType
                        };
                        updateConfig(paymentConfig, logisticsConfig);
                      }}
                      options={deliveryOptions}
                    />
                  ) : (
                    <span className="text margin-top-10">
                      {
                        deliveryOptions.find(
                          o => o.value === config.logisticsConfig.LogisticsType
                        ).label
                      }
                    </span>
                  )}
                </>
              );

            case "ecpay_cvs":
              return (
                <>
                  <p className="title">選擇取貨門市</p>
                  {isEditable ? (
                    <div>
                      <RadioSelectorGroupField
                        value={config.logisticsConfig}
                        onChange={cvsType =>
                          updateConfig(config.paymentConfig, cvsType)
                        }
                        options={cvsOptions}
                      />
                      <Widget.Row align="center">
                        <Widget.Col>
                          <div className="display-text">
                            門市店號：{config.CVSStoreID || "---"}
                          </div>
                          <div className="display-text margin-top-10">
                            門市名稱：{config.CVSStoreName || "---"}
                          </div>
                        </Widget.Col>
                        {!config.logisticsConfig.LogisticsSubType ? (
                          <a
                            className="button"
                            style={{ background: "#a9a9a9" }}
                          >
                            選取門市
                          </a>
                        ) : (
                          <a
                            className="button"
                            href={
                              Constants.env === "stg"
                                ? `${
                                    Constants.apiUrl
                                  }/checkout/cvs/stage/?token=${window.localStorage.getItem(
                                    "token"
                                  )}`
                                : `${
                                    Constants.apiUrl
                                  }/checkout/cvs/?token=${window.localStorage.getItem(
                                    "token"
                                  )}`
                            }
                          >
                            選取門市
                          </a>
                        )}
                      </Widget.Row>
                    </div>
                  ) : (
                    <Widget.Col>
                      <p className="title">取貨門市</p>
                      <div className="display-text">
                        門市店號：{cartConfig.CVSStoreID || "---"}
                      </div>
                      <div className="display-text margin-top-10">
                        門市名稱：{cartConfig.CVSStoreName || "---"}
                      </div>
                    </Widget.Col>
                  )}
                </>
              );
          }
        })()}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;

  & .margin-top-10 {
    margin-top: 10px;
  }

  & .title {
    margin-top: 52px;
    font-size: 16px;
    color: #575757;
  }

  & > .hint {
    font-size: 14px;
    color: #a4a4a4;
  }

  & .text {
    font-size: 16px;
    color: grey;
    display: block;
    padding-left: 10px;
    border-left: 3px solid lightgrey;
  }

  & .display-text {
    min-width: 180px;
    font-size: 14px;
    color: #575757;
  }

  & .button {
    min-width: 116px;
    box-shadow: 0 2px 6px 0 rgba(29, 64, 117, 0.5);
    border: 0px;
    padding: 10px;
    background: #0075c1;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
`;

export default OrderPaymentInfo;
